import React from "react";
import "./CustomSwitch.css";

export const CustomSwitch = ({ checked, onChange }) => {
  const handleToggle = () => {
    onChange(!checked);
  };

  return (
    <div className={`switch ${checked ? "switch-on" : "switch-off"}`} onClick={handleToggle}>
      <div className="switch-thumb" />
    </div>
  );
};

