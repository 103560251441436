import React from 'react'
import './ManipulateCoins.css';
import axios from 'axios'
import { useState } from 'react';
import Swal from 'sweetalert2'
import { fichas_success, fichas_con_promo, fichas_bajadas } from '../../../assets/data';
import { Row, Col, Dropdown, InputGroup, Form } from 'react-bootstrap'
import { useEffect } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { GrSubtractCircle } from 'react-icons/gr'
import { TbGift, TbRosetteNumber1 } from 'react-icons/tb'
import { BiLoaderCircle } from 'react-icons/bi'
import { getApiConfig } from '../../../API/getConfig';
import { BsInfoCircle, BsPlusCircleFill } from 'react-icons/bs';
import { RiHeartFill } from 'react-icons/ri';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { fetchAdminPublicInfo } from '../../Copy-pastes/API';

export const ManipulateCoins = (props) => {

    const [form, setForm] = useState({ name: '', quantity: '', destinationAccountName: '', senderName: '' })
    const [loading, setLoading] = useState({ loading: false })
    const [historial, setHistorial] = useState([])
    const [showFullHistorial, setShowFullHistorial] = useState(false)
    const [historialRef, setHistorialRef] = useState(null);

    const [mostrarHistorial, setMostrarHistorial] = useState(false);
    const [errorsQuantity, setErrorQuantities] = useState(0)
    const [messagesAddCoins, setMessagesAddCoins] = useState([])
    const [messagesSubtractCoins, setMessagesSubtractCoins] = useState([])
    const [messagesPromo, setMessagesPromo] = useState([])
    const [adminInfo, setAdminInfo] = useState({})
    const platform = props?.ownerInfo?.platform ?? ''
    const urlPage = props?.ownerInfo?.pageLink ?? ''

    // const adminName = process.env.REACT_APP_CAJERO
    const [adminName, setAdminName] = useState(sessionStorage.getItem('ownerName'));

    useEffect(() => {
        if (historialRef) { historialRef.scrollTop = historialRef.scrollHeight }
        setMessages()
        getPublicAdminInfo();
    }, [historialRef, historial]);
    const showPrimeraCargaButton = props?.ownerInfo?.id === "7db3f43d-585f-476d-91dc-a0d2f684fa6b";

    const getPublicAdminInfo = async () => {
        const info = await fetchAdminPublicInfo()
        if (info) {
            setAdminInfo(info)
        }
    }

    const setMessages = async () => {
        let config = getApiConfig()
        await axios.post('/getStaticButton', { name: 'static_add', owner: adminName }, config)
            .then(res => setMessagesAddCoins(res))

        await axios.post('/getStaticButton', { name: 'static_bonus', owner: adminName }, config)
            .then(res => setMessagesPromo(res))

        await axios.post('/getStaticButton', { name: 'static_subtract', owner: adminName }, config)
            .then(res => setMessagesSubtractCoins(res))
    }


    const handleManipulateSuccess = async (user, q) => {
        await navigator.clipboard.writeText(fichas_success(user, q))
    }

    const handleManipulateSuccessWithPromo = async (user, q, bonificacion) => {
        await navigator.clipboard.writeText(fichas_con_promo(user, q, bonificacion))
    }

    const handleFichasBajadas = async () => {
        await navigator.clipboard.writeText(fichas_bajadas)
    }

    const handleShowFullHistorial = () => {
        setShowFullHistorial(!showFullHistorial)
    }

    const getTime = () => {
        let d = new Date();
        let hora = d.getHours();
        let minutos = d.getMinutes();
        if (hora < 10) hora = "0" + hora;
        if (minutos < 10) minutos = "0" + minutos;
        return (hora + ":" + minutos)
    }

    const handleLoader = () => {
        setLoading(true)
    }

    const handleCopyFromHistory = (record) => {
        let text;
        if (record.type === 'normal') text = `${record.quantity} fichas cargadas a ${record.user}`
        if (record.type === 'promo') text = `${record.quantity} fichas cargadas a ${record.user}. 10% de promo por ser primera vez agregado.`

        Swal.fire({
            text: record.success ? text : record.info,
            icon: record.success ? `success` : `error`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Copiar mensaje',
            showConfirmButton: record.success ? true : false,

        }).then((e) => {
            if (e.isConfirmed && record.type === 'promo') { handleManipulateSuccessWithPromo(record.user, record.quantity) }
            if (e.isConfirmed && record.type === 'normal') { handleManipulateSuccess(record.user, record.quantity) }
        })
    }

    const nameHandler = async (e) => {
        if (((e.target.value.trim()).length) > 16) { Swal.fire('El nombre no puede contener más de 16 carácteres. Verifica que esté bien.'); return }
        // if (!(/^[A-Za-z0-9]*$/.test(e.target.value.trim()))) { Swal.fire('El nombre no puede contener carácteres especiales. Solo letras y números'); return }
        setForm({ ...form, [e.target.name]: e.target.value.trim() })
    }

    const inputHandler = async (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function iluminateBorderSuccess() {
        let div = document.getElementById("historial_coins");
        if (div) {
            div.classList.add("success");
            setTimeout(function () {
                div.classList.remove("success");
            }, 450);
        }
    }

    const toggleMostrarHistorial = () => {
        setErrorQuantities(0)
        setMostrarHistorial(!mostrarHistorial);
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };




    ///********** SUBTRACT FICHAS ****************//

    const handleSubtract = async (e) => {

        e.preventDefault();

        if (form.quantity < 1 || form.name < 1) { Swal.fire({ text: 'Debes introducir nombre y cantidad de fichas.' }); return }
        if (!loading) { return }
        if (isNaN(Number(form.quantity))) {
            Swal.fire({ text: 'Debes introducir un número válido.' });
            return;
        }
        // handleFichasBajadas()

        setForm({
            name: '',
            quantity: '',
            senderName: '',
            destinationAccountName: props?.fetchedData?.titular ?? ''
        })

        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }


        const copyMessage = async (text, name) => {
            let updatedMessage = text
                .replaceAll('__CANTIDAD__', (form.quantity))
                .replaceAll('__USUARIO__', form.name)
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__LINK__', `${urlPage}`)

            await navigator.clipboard.writeText(updatedMessage);
        }

        // let messages = await axios.post('/getStaticButton', { name: 'static_subtract', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesSubtractCoins?.data?.Messages.length);
        let randomValue = messagesSubtractCoins?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text)


        try {
            let config = getApiConfig()
            await axios.post(`/subtractC`, { name: form.name, quantity: form.quantity, adminName: adminName, loggedUser: props?.userLoggedName }, config)
                .then((result) => {
                    if (result?.data?.success) {
                        let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: true, type: 'bajar' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: false, info: result.data, type: 'bajar' }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        const errorMessage = result?.data?.message ?? 'Ha habido un error. Reinicia e intenta de nuevo.'
                        Swal.fire({ icon: 'error', text: errorMessage });
                    }
                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            let errorMessage = error?.response?.data?.message ?? 'ERROR: No se ha podido bajar, verifica tu conexión y que el usuario tenga suficientes fichas disponibles.'
            let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: false, info: errorMessage, type: 'bajar' }
            setHistorial([...historial, record])
            if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
            Swal.fire({ icon: 'error', text: errorMessage });
            if (!props.fastMode) { setLoading(true) }
        }
    }


    ///********** ADD FICHAS ****************//


    const confirmAddCoins = async (e, isBonus) => {
        e.preventDefault();
        if (form.quantity < 1 || form.name < 1) { Swal.fire({ text: 'Debes introducir nombre y cantidad de fichas.' }); return }
        if (!loading) { console.log('ta entrando aca'); return }

        if (isNaN(Number(form.quantity))) {
            Swal.fire({ text: 'Debes introducir un número válido.' });
            return;
        }

        if (adminInfo?.useMaxLoadValue && adminInfo?.maxLoadValue && (Number(form.quantity) >= adminInfo?.maxLoadValue)) {
            Swal.fire({
                // text: `¿Estás por cargar ${formatQuantityWithCommas} fichas al usuario ${form.name}, deseas continuar?`,
                html: `¿Estás por cargar <span style="color:var(--main-color)">${form.quantity}</span> fichas ${isBonus ? 'con <span style="color:var(--main-color)">100% de bonificación</span>' : ''}  al usuario <span style="color:var(--main-color)">${form.name}</span>, deseas continuar?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'var(--main-color)',
                // cancelButtonColor: 'var(--main-color)',

                confirmButtonText: 'Si, Cargar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCoins(e, isBonus);
                } else {
                    return;
                }
            });
        } else {
            handleCoins(e, isBonus);
        }
    }


    const handleCoins = async (e, isBonus) => {
        e.preventDefault();

        setForm({
            name: '',
            quantity: '',
            senderName: '',
            destinationAccountName: props?.fetchedData?.titular ?? ''
        })

        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }


        const copyMessage = async (text, name) => {
            let updatedMessage = text
                .replaceAll('__CANTIDAD__', form.quantity)
                .replaceAll('__USUARIO__', form.name)
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__LINK__', `${urlPage}`)

            await navigator.clipboard.writeText(updatedMessage);
        }

        // let messages = await axios.post('/getStaticButton', { name: 'static_add', owner: 'platcas' })

        let randomIndex = Math.floor(Math.random() * messagesAddCoins?.data?.Messages.length);
        let randomValue = messagesAddCoins?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text)
        setDropdownOpen(false);

        try {
            let config = getApiConfig()
            await axios.post(`/addC`, {
                name: form.name,
                quantity: form.quantity,
                adminName: adminName,
                loggedUser: props?.userLoggedName,
                isBonus: isBonus ? true : false,
                destinationAccountName: form.destinationAccountName,
                senderName: form.senderName,
            }, config)
                .then((result) => {
                    if (result?.data?.success) {
                        let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: true, type: 'normal' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: false, info: result.data, type: 'normal' }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        const errorMessage = result?.data?.message ?? 'Ha habido un error. Reinicia e intenta de nuevo.'
                        Swal.fire({ icon: 'error', text: errorMessage });
                    }
                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            let errorMessage = error?.response?.data?.message ?? 'ERROR: No se ha podido cargar, verifica tu conexión y que tengas fichas disponibles.'
            let record = { user: form.name, quantity: form.quantity, hour: getTime(), success: false, info: errorMessage, type: 'normal' }
            setHistorial([...historial, record])
            if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
            Swal.fire({ icon: 'error', text: errorMessage });
            if (!props.fastMode) { setLoading(true) }
        }

    }



    ///********** PROMO ****************//


    const confirmAddCoinsWithPromo = async (e, bonificacion) => {
        e.preventDefault();

        if (form.quantity < 1) { Swal.fire({ text: 'Debes introducir una cantidad (sin contar la promoción)' }); return }
        if (!form.name) { Swal.fire({ text: 'Debes introducir un nombre de usuario' }); return }
        if (!bonificacion) { Swal.fire({ text: 'Debes elegir una bonificación.' }); return }

        if (isNaN(Number(form.quantity))) {
            Swal.fire({ text: 'Debes introducir un número válido.' });
            return;
        }
        if (adminInfo?.useMaxLoadValue && adminInfo?.maxLoadValue && (Number(form.quantity) >= adminInfo?.maxLoadValue)) {
            Swal.fire({
                // text: `¿Estás por cargar ${formatQuantityWithCommas} fichas al usuario ${form.name}, deseas continuar?`,
                html: `¿Estás por cargar <span style="color:var(--main-color)">${form.quantity}</span> fichas al usuario <span style="color:var(--main-color)">${form.name}</span> con una bonificación de <span style="color:var(--main-color)">${bonificacion}%</span>, deseas continuar?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'var(--main-color)',
                // cancelButtonColor: 'var(--main-color)',
                confirmButtonText: 'Si, Cargar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCoinsWithPromo(e, bonificacion);
                } else {
                    return;
                }
            });
        } else {
            handleCoinsWithPromo(e, bonificacion);
        }
    }

    const handleCoinsWithPromo = async (e, bonificacion) => {
        e.preventDefault();
        setForm({
            name: '',
            quantity: '',
            senderName: '',
            destinationAccountName: props?.fetchedData?.titular ?? ''
        })

        setLoading(false)
        if (props.fastMode) { setTimeout(handleLoader, 3000) }

        let bonus = bonificacion ? bonificacion : 10

        let finalCharge = Number(form.quantity) + (Number(form.quantity) * bonus / 100)

        const copyMessage = async (text, name) => {
            let updatedMessage = text
                .replaceAll('__CANTIDAD__', finalCharge)
                .replaceAll('__USUARIO__', form.name)
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__LINK__', `${urlPage}`)
                .replaceAll('__BONUS__', bonus)

            await navigator.clipboard.writeText(updatedMessage);
        }

        // let messages = await axios.post('/getStaticButton', { name: 'static_bonus', owner: 'platcas' })
        let randomIndex = Math.floor(Math.random() * messagesPromo?.data?.Messages.length);
        let randomValue = messagesPromo?.data?.Messages[randomIndex];
        if (randomValue) copyMessage(randomValue.text)
        setDropdownOpen(false);


        try {
            let config = getApiConfig()
            await axios.post(`/addwithpromoC`, {
                name: form.name,
                quantity: form.quantity,
                bonus: bonus,
                adminName: adminName,
                loggedUser: props?.userLoggedName,
                destinationAccountName: form.destinationAccountName,
                senderName: form.senderName,
            }, config)
                .then((result) => {
                    if (result?.data?.success) {
                        let record = { user: form.name, quantity: finalCharge, hour: getTime(), success: true, info: result.data, type: 'promo' }
                        setHistorial([...historial, record])
                        iluminateBorderSuccess()
                    } else {
                        let record = { user: form.name, quantity: finalCharge, hour: getTime(), success: false, info: result.data, type: 'promo' }
                        setHistorial([...historial, record])
                        if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
                        const errorMessage = result?.data?.message ?? 'Ha habido un error. Reinicia e intenta de nuevo.'
                        Swal.fire({ icon: 'error', text: errorMessage });
                    }

                })
            if (!props.fastMode) { setLoading(true) }

        } catch (error) {
            let errorMessage = error?.response?.data?.message ?? 'ERROR: No se ha podido bajar, verifica tu conexión y que el usuario tenga suficientes fichas disponibles.'
            let record = { user: form.name, quantity: finalCharge, hour: getTime(), success: false, info: errorMessage, type: 'promo' }
            setHistorial([...historial, record])
            if (!mostrarHistorial) setErrorQuantities(errorsQuantity + 1)
            Swal.fire({ icon: 'error', text: errorMessage });
            if (!props.fastMode) { setLoading(true) }
        }
    }

    const handleCustomPercentage = (event) => {
        const value = event.target.value;
        if (/^\d{0,2}$/.test(value)) {
            setCustomPercentage(value);
        }
    };

    const [customPercentage, setCustomPercentage] = useState('');

    const applyCustomPercentage = () => {
        if (customPercentage) {
            handleCoinsWithPromo(null, parseInt(customPercentage));
            setCustomPercentage(''); // Reset después de aplicar
        }
    };

    useEffect(() => {
        setForm({
            destinationAccountName: props?.fetchedData?.titular ?? ''
        })
    }, [props?.fetchedData?.titular])

    return (

        <div className='addCoins_container mx-3 py-2'>


            <form onSubmit={handleCoins} className='m-auto'>

                <Col style={{ justifyContent: "center" }} className="input-group mb-1 container-fluid">
                    <Col>
                        <div className="my-2 text-white d-flex justify-content-start px-1 w-100" style={{ fontSize: '14px' }}>
                            Panel de Carga
                        </div>
                        {/* Inputs */}

                        <Row className='mb-2 d-flex justify-content-center g-0'>
                            <Col className='mx-0 px-1'>
                                {/* <div className="d-flex justify-content-start" style={{ fontSize: '12px' }}>Nombre de usuario</div> */}
                                <input value={form.name} onChange={nameHandler} name='name' type="text" className="form-control mx-0 customDarkInput" placeholder="Usuario" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            </Col>
                            <Col className='mx-0 px-1'>
                                {/* <div className="d-flex justify-content-start" style={{ fontSize: '12px' }}>Cantidad de fichas</div> */}
                                <input value={form.quantity} onChange={nameHandler} name='quantity' type="text" className="form-control mx-0 customDarkInput" placeholder="Cantidad" aria-label="Recipient's username" aria-describedby="basic-addon2" list="quantity-options" autoComplete='off' />
                                <datalist id="quantity-options">
                                    <option value="1000"></option>
                                    <option value="1500"></option>
                                    <option value="2000"></option>
                                    <option value="2500"></option>
                                    <option value="3000"></option>
                                    <option value="4000"></option>
                                    <option value="5000"></option>
                                    <option value="10000"></option>
                                </datalist>
                            </Col>
                        </Row>
                        <Row className='mb-2 d-flex justify-content-center g-0'>
                            <Col className='mx-0 px-1'>
                                <input maxLength={50} value={form.senderName} onChange={inputHandler} name='senderName' type="text" className="form-control mx-0 customDarkInput" placeholder="Nombre Remitente" aria-label="Nombre remitente" aria-describedby="basic-addon2" />
                                <div className="d-flex text-secondary g-1 align-items-center" style={{ fontSize: '11px' }}>
                                    <BsInfoCircle size={10} className="mx-1" /> Nombre de quien envía.
                                    <span className="mx-1" style={{ fontSize: '9px' }}>(Opcional)</span>
                                </div>
                            </Col>
                            <Col className='mx-0 px-1'>
                                <input maxLength={50} value={form.destinationAccountName} onChange={inputHandler} name='destinationAccountName' type="text" className="form-control mx-0 customDarkInput" placeholder="Cuenta Destino" aria-label="Recipient's username" aria-describedby="basic-addon2" autoComplete='off' />
                                <div className="d-flex text-secondary g-1 align-items-center" style={{ fontSize: '11px' }}>
                                    <BsInfoCircle size={10} className="mx-1" /> Nombre de quien recibe.
                                    <span className="mx-1" style={{ fontSize: '9px' }}>(Opcional)</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* <form className='mx-5 px-4 m-auto' onSubmit={newUser}>
                        <div className="my-2 text-white d-flex justify-content-start">
                            Panel de Usuarios
                        </div>
                        <div className="input-group mb-3">
                            <input value={form.name} onChange={nameHandler} type="text" className="form-control form-control-sm customDarkInput" placeholder="Username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-gro-append" title='Crear usuario'>
                                <button disabled={!loading} style={{ minWidth: "10%" }} className="btn btn-sm btn-green text-black" type="submit">{loading ? <BsPersonFillAdd size={20} className='p-0 m-0'></BsPersonFillAdd > : 'Loading..'}</button>
                            </div>
                        </div>
                    </form> */}

                    {/* <Row>
                        <button disabled={!loading} style={{ marginLeft: "1rem" }} className="btn btn-outline-primary crear_usuario mx-2 py-1" type="submit">{loading ? 'Cargar' : 'Loading..'}</button>
                        <div>
                            <button type='button' onClick={handleCoinsWithPromo} disabled={!loading} className="btn btn-outline-primary crear_usuario mx-2 py-1">{loading ? 'Primera carga' : 'Loading..'}</button>
                        </div>
                    </Row> */}
                    <Row className='mx-5 w-100 m-auto text-white pb-2 mt-2' style={{ fontSize: '0.7rem' }}>

                        {loading ?

                            (
                                <>
                                    <Col type='submit' onClick={confirmAddCoins} className='handlerButton p-2 mx-1 rounded'>
                                        <BsPlusCircleFill size={20}></BsPlusCircleFill>
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? 'Cargar' : ''}</span></Row>
                                    </Col>

                                    {showPrimeraCargaButton &&
                                        < Col onClick={confirmAddCoinsWithPromo} className='handlerButton p-2 mx-1 rounded'>
                                            <TbRosetteNumber1 size={20}></TbRosetteNumber1>
                                            <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? 'Primera carga' : ''}</span></Row>
                                        </Col>
                                    }

                                    <Col className='handlerButton p-2 mx-1 rounded'>
                                        <div onClick={toggleDropdown}>
                                            <RiHeartFill size={20} />
                                            <Row className='d-flex justify-content-center'>
                                                <span className='text-center'>{loading ? 'Bonificaciones' : ''}</span>
                                            </Row>
                                        </div>

                                        <Dropdown show={dropdownOpen}>
                                            <Dropdown.Menu className='text-white dark-dropdown-custom '>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 10)} >Bonificar 10%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 15)} >Bonificar 15%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 20)} >Bonificar 20%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 25)} >Bonificar 25%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 30)} >Bonificar 30%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 35)} >Bonificar 35%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 40)} >Bonificar 40%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 50)} >Bonificar 50%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoinsWithPromo(event, 60)} >Bonificar 60%</Dropdown.Item>
                                                <Dropdown.Item className="text-secondary" onClick={(event) => confirmAddCoins(event, true)} >Bonificación total</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </Col>


                                    <Col onClick={handleSubtract} className='handlerButton p-2 mx-1 rounded'>
                                        <MdOutlineRemoveCircle size={20}></MdOutlineRemoveCircle  >
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? 'Bajar' : ''}</span></Row>
                                    </Col>
                                </>
                            )
                            :
                            (

                                <>
                                    < Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                        <BiLoaderCircle size={30}></BiLoaderCircle>
                                    </Col>

                                    < Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                        <BiLoaderCircle size={30}></BiLoaderCircle>
                                    </Col>

                                    {showPrimeraCargaButton && < Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                        <BiLoaderCircle size={30}></BiLoaderCircle>
                                    </Col>}

                                    < Col className='handlerButton mx-1 px-2 rounded subtractButton'>
                                        <Row className='d-flex justify-content-center'><span className='text-center'>{loading ? '' : 'Loading..'}</span></Row>
                                        <BiLoaderCircle size={30}></BiLoaderCircle>
                                    </Col>
                                </>
                            )
                        }

                    </Row>
                </Col>
                <input type="submit" hidden />

            </form >
            {
                historial.length > 0 &&
                <>
                    <div id='historial_users' className={`historial_container mt-2 position-relative ${mostrarHistorial ? 'mostrar-historial' : ' border-0'}`}>

                        {mostrarHistorial &&
                            <button
                                className='btn btn-sm btn-dark p-0 px-1 eye'
                                title='Ocultar historial'
                                type="button"
                                onClick={toggleMostrarHistorial}
                            >
                                <FontAwesomeIcon
                                    icon={mostrarHistorial && faEye}
                                />
                            </button>}

                        <Col ref={(ref) => setHistorialRef(ref)}
                            onClick={handleShowFullHistorial} className='text-center fullhistorial mx-1'>
                            {
                                mostrarHistorial ?
                                    (
                                        historial
                                            .map(record =>
                                            (
                                                <Row className={`d-flex justify-content-center ${record.success ? 'text-success' : 'text-danger'}`}>
                                                    <span title={record.info} className='text-center' style={{ fontSize: '12px' }}>
                                                        <span className='historyHour' onClick={() => handleCopyFromHistory(record)}>[{record.hour}]</span>
                                                        {record.type === 'bajar' ? 'Bajar' : 'Cargar'} {record.quantity} fichas a {record.user}:
                                                        {record.success ? ' hecho' : ' fallo'}
                                                    </span>

                                                </Row>
                                            )
                                            )
                                    )
                                    :
                                    <div className="text-secondary showHistory" style={{ fontSize: '12px' }} onClick={toggleMostrarHistorial}
                                    >
                                        {errorsQuantity > 0 ? <span className='text-danger'>Tenés {errorsQuantity} errores sin ver</span> : "Mostrar historial"}
                                    </div>
                            }
                        </Col>
                    </div>
                </>
            }
        </div >
    )
}
