import React, { useEffect } from 'react'
import './CopyPastes.css'
import { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { fetchAdminPublicInfo, fetchButtons } from './API'

export const CopyPastes = (props) => {


  const [bienvenidaButtons, setBienvenidaButtons] = useState([])
  const [infoButtons, setInfoButtons] = useState([])
  const [premiosButtons, setPremiosButtons] = useState([])
  const [fichasButtons, setFichasButtons] = useState([])
  const [adminInfo, setAdminInfo] = useState({});

  useEffect(() => {
    fetchButtons().then(res => {
      const bienvenidaButtons = [];
      const infoButtons = [];
      const premiosButtons = [];
      const fichasButtons = [];

      res.forEach(button => {
        if (button.category === 'bienvenida') {
          bienvenidaButtons.push(button);
        } else if (button.category === 'info') {
          infoButtons.push(button);
        } else if (button.category === 'premios') {
          premiosButtons.push(button);
        } else if (button.category === 'fichas') {
          fichasButtons.push(button)
        }
      });

      setBienvenidaButtons(bienvenidaButtons);
      setInfoButtons(infoButtons);
      setPremiosButtons(premiosButtons);
      setFichasButtons(fichasButtons);
    });

    getPublicAdminInfo();
  }, []);

  const getPublicAdminInfo = async () => {
    const info = await fetchAdminPublicInfo()
    if (info) {
      setAdminInfo(info)
    }
  }

  const handleCopyMessage = (message) => {
    const updatedMessages = message.map(m => {
      let updatedMessage = m.text
        .replaceAll('__CBU__', (props.fetchedData?.CBU || 'Consultar CBU'))
        .replaceAll('__ALIAS__', props.fetchedData?.alias || 'Consultar alias')
        .replaceAll('__TITULAR__', props.fetchedData?.titular || 'Consultar titular')
        .replaceAll('__HORA__', props.fetchedData?.hour_limit || 'Consultar ')
        .replaceAll('__LINK__', adminInfo?.pageLink || 'https://jugalodos.com')
        .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
        .replaceAll('__NOMBRE__', props.fetchedData?.nombre || '')
      return updatedMessage;
    });

    navigator.clipboard.writeText(updatedMessages[Math.floor(Math.random() * updatedMessages.length)]);
  };



  return (

    <React.Fragment>

      {bienvenidaButtons.length > 0 &&
        <div className='my-0 py-0 mt-3'>
          <Col className="darkBackground mb-2 py-1" style={{ minWidth: '520px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
              <span className='text-secondary' style={{ fontSize: '10px' }}>BIENVENIDA</span>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
            </div>

            {
              bienvenidaButtons.map((button, index) =>
              (<>

                <button
                  key={index}
                  title={button.Messages[0]?.text}
                  onClick={() => handleCopyMessage(button.Messages)}
                  className="btn btn-customdark my-0 py-0 m-1"
                  type="submit">{button.name}
                </button>

              </>))}

          </Col>
        </div>}

      {infoButtons.length > 0 &&
        <div>
          <Col className="darkBackground mb-2 py-1" style={{ minWidth: '520px' }}>
            <div className='my-0 mx-0' style={{ display: 'flex', alignItems: 'center' }}>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
              <span className='text-secondary' style={{ fontSize: '10px' }}>INFO</span>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
            </div>

            {
              infoButtons.map((button, index) =>
              (<>
                <button
                  key={index}
                  title={button.Messages[0]?.text}
                  onClick={() => handleCopyMessage(button.Messages)}
                  className="btn btn-customdark my-0 py-0 m-1"
                  type="submit">{button.name}
                </button>

              </>))}

          </Col>
        </div>}

      {premiosButtons.length > 0 &&
        <div>
          <Col className="darkBackground mb-2 py-1" style={{ minWidth: '520px' }}>
            <div className='my-0 mx-0' style={{ display: 'flex', alignItems: 'center' }}>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
              <span className='text-secondary' style={{ fontSize: '10px' }}>PREMIOS</span>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
            </div>
            {
              premiosButtons.map((button, index) =>
              (<>
                <button
                  key={index}
                  title={button.Messages[0]?.text}
                  onClick={() => handleCopyMessage(button.Messages)}
                  className="btn btn-customdark my-0 py-0 m-1"
                  type="submit">{button.name}
                </button>

              </>))}

          </Col>
        </div>}

      {fichasButtons.length > 0 &&
        <div>
          <Col className="darkBackground mb-2 py-1" style={{ minWidth: '520px' }}>
            <Row className='my-0 mx-0' style={{ display: 'flex', alignItems: 'center' }}>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
              <span className='text-secondary' style={{ fontSize: '10px' }}>FICHAS</span>
              <hr className='my-0 py-0' style={{ flex: 1 }} />
            </Row>

            {
              fichasButtons.map((button, index) =>
              (<>
                <button
                  key={index}
                  title={button.Messages[0]?.text}
                  onClick={() => handleCopyMessage(button.Messages)}
                  className="btn btn-customdark my-0 py-0 m-1"
                  type="submit">{button.name}
                </button>

              </>))}
          </Col>
        </div>}

    </React.Fragment >
  )
}
