import React, { useCallback, useEffect, useState } from 'react'
import './Home.css';
import axios from 'axios'
import { NewUser } from './NewUser/NewUser';
import { ManipulateCoins } from './ManipulateCoins/ManipulateCoins';
import { CopyPastes } from '../Copy-pastes/CopyPastes';
import { DataHandler } from './DataHandler/DataHandler';

import { Row, Col, Container } from 'react-bootstrap';
import { MoreInfo } from './MoreInfo/MoreInfo';
import { RiRadioButtonLine } from 'react-icons/ri'
import { MdCircleNotifications, MdNavigateNext, MdNotificationsNone, MdWarning } from 'react-icons/md';
import { NotificationsModal } from './NotificationsModal/NotificationsModal';
import { BiUser, BiUserCircle } from 'react-icons/bi';
import { checkValidOwner, postAuth, postTrack, testUserToken } from '../../API/API';
import Cookies from 'js-cookie';
import { getApiConfig } from '../../API/getConfig';
import Swal from 'sweetalert2';
import background from '../../assets/clientloginbg.png'
import { BsInfoCircle } from 'react-icons/bs';

export const Home = () => {

    // const { adminName } = useParams();

    const [fetchedData, setFetchedData] = useState()
    const [fastMode, setFastMode] = useState(false)
    const [password, setPassword] = useState([]);
    const [logged, setLogged] = useState(false)
    const [isPressed, setIsPressed] = useState([]);
    const [disableButtons, setDisableButtons] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState(false)
    const [connecting, setConnecting] = useState(false)
    const [loginWithUseAndPassword, setLoginWithUserAndPassword] = useState(true)
    const [errorLogin, setErrorLogin] = useState(false)
    const [keepSession, setKeepSession] = useState(false)
    const [userLoggedName, setUserLoggedName] = useState('')
    const [showUserMenu, setShowUserMenu] = useState(false)

    const [localUser, setLocalUser] = useState(localStorage.getItem('loggedUser'));
    const [adminName, setAdminName] = useState(sessionStorage.getItem('ownerName'));

    // Notifications  
    const [notifications, setNotifications] = useState([])
    const [showNotificationsModal, setShowNotificationModal] = useState(false)
    const [notificationsCount, setNotificationsCount] = useState(0)

    const [userInfo, setUserInfo] = useState({})
    const [ownerInfo, setOwnerInfo] = useState({})
    // const adminName = process.env.REACT_APP_CAJERO
    const token = Cookies.get("Token")

    // Login form
    const [loginForm, setLoginForm] = useState({
        userName: '',
        password: '',
        ownerName: '',
    })

    useEffect(() => {
        initializer()
        checkNotifications()
        testToken()
    }, [token]);

    const testToken = async () => {
        let test = await testUserToken();
        console.log(test, 'test')
        if (test?.data?.status) {
            setUserInfo(test.data.user)
            setOwnerInfo(test.data.user.owner)
            if (test?.data?.user?.owner?.platform === '') {
                document.documentElement.style.setProperty('--main-color', '#00C985');
            }

            if (test?.data?.user?.owner?.platform === 'ganamos') {
                document.documentElement.style.setProperty('--main-color', '#e100ff');
            }
            if (test?.data?.user?.owner?.platform === 'pulpobet') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            }
            if (test?.data?.user?.owner?.platform === 'megafaraon') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            }
            setLogged(true);
        }
    }

    const checkOwner = async () => {
        try {
            const token = Cookies.get("Token")

            if (!token) { return }
            let response = await checkValidOwner(sessionStorage.getItem('ownerName'))
            if (response?.status !== 200) { throw Error() } else { return }
        } catch (error) {
            setLogged(false)
            setErrorLogin(true)
        }
    }

    useEffect(() => {
        window.addEventListener('storage', checkOwner);
        return () => { window.removeEventListener('storage', checkOwner) };
    }, [adminName, fetchedData])

    const checkNotifications = () => {
        const storedData = localStorage.getItem('notificationsData');
    }

    const initializer = async () => {
        await getNotifications()
        await getData()
        await getStatus()
        // tryConnect()
    }



    const getStatus = async () => {
        let config = getApiConfig()
        setConnecting(true)
        try {
            await axios.post(`/testConnection`, { adminName: adminName }, config)
                .then((result) => {
                    setConnectionStatus(true)
                    setConnecting(false)
                })
                .catch((error) => {
                    // tryConnect()
                    setConnecting(false)
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
        return
    }

    const tryConnect = async () => {
        setConnecting(true)
        let config = getApiConfig()
        try {
            await axios.post(`/extract`, { adminName: adminName }, config)
                .then((result) => {
                    setConnectionStatus(true)
                    setConnecting(false)
                })
                .catch((error) => {
                    console.log(error)
                    setConnectionStatus(false)
                    setConnecting(false)
                })
        } catch (error) {
            console.log(error)
            setConnecting(false)
        }
        return
    }

    const getData = async () => {
        let config = getApiConfig()

        try {
            await axios.get(`/getCbu?adminName=${adminName}`, config).then((result) => {
                setFetchedData(result.data)
            })
        } catch (error) {
            console.log(error)
        }
        return
    }


    const getNotifications = async () => {
        let config = getApiConfig()

        try {
            const response = await axios.get(`/getNotifications`, config);
            const newNotifications = response.data;
            setNotifications(newNotifications)
            const storedData = localStorage.getItem('notificationsData');
            const storedNotifications = storedData ? JSON.parse(storedData) : [];

            // Identificar las notificaciones no vistas
            const unseenNotifications = newNotifications.filter((newNotif) => {
                return !storedNotifications.some((storedNotif) => storedNotif.id === newNotif.id);
            });

            // Sumar la cantidad de notificaciones no vistas a notificationsCount
            setNotificationsCount((prevCount) => prevCount + unseenNotifications.length);

            // Actualizar localStorage con las nuevas notificaciones
            const updatedNotifications = [...storedNotifications, ...newNotifications];
            localStorage.setItem('notificationsData', JSON.stringify(updatedNotifications));
        } catch (error) {
            console.log(error);
        }
    };


    const handlePassword = (event) => {
        setIsPressed({ [event.target.id]: true });
        setTimeout(() => {
            setIsPressed({ [event.target.id]: false });
        }, 300);

        const number = event.target.id;

        if (password.length < 4) {
            setPassword([...password, number])
        } else {
            checkPassword()
            setPassword([])
        }
    }

    const key = process.env.REACT_APP_PW

    const handleDisableButtons = () => {
        setDisableButtons(true);
        setTimeout(() => {
            setDisableButtons(false);
        }, 3000);
    }

    const checkPassword = () => {
        if (password.join("") === key) {
            setLogged(true)
            setUserLoggedName('Invitado')
        } else {
            handleDisableButtons()
        }
    }

    const handleLoginWithPassword = async (e) => {
        e.preventDefault();

        let auth = await postAuth(loginForm)
        if (auth?.status === 200 && auth.data.token) {
            setLogged(true)
            setUserLoggedName(loginForm.userName)

            if (auth.data.platform === '') {
                document.documentElement.style.setProperty('--main-color', '#00C985');
            }

            if (auth.data.platform === 'pulpobet') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            }
            if (auth.data.platform === 'megafaraon') {
                document.documentElement.style.setProperty('--main-color', '#6079ff');
            }

            Cookies.set('Token', auth.data.token)
            if (keepSession) {
                localStorage.setItem('loggedUser', loginForm.userName)
                localStorage.setItem('userLocalPw', loginForm.password)
            } else {
                sessionStorage.setItem('ownerName', loginForm.ownerName)
                // sessionStorage.setItem('loggedUser', loginForm.userName)
                // sessionStorage.setItem('userLocalPw', loginForm.password)
            }

        } else {
            const errorMessage = auth?.response?.data?.msg
            if (errorMessage) {
                Swal.fire({ icon: 'warning', text: errorMessage });
            } else {
                setErrorLogin(true)
            }
        }
    }

    const handleLoginForm = (e) => {
        setErrorLogin(false)
        setLoginForm({
            ...loginForm,
            [e.target.name]: e.target.value
        })
    }

    // const handleTryLogin = async (username, password) => {
    //     let payload = { userName: username, password: password }
    //     let auth = await postAuth(payload)
    //     if (auth?.status === 200) {
    //         setLogged(true)
    //         setUserLoggedName(username)
    //     } else {
    //         setErrorLogin(true)
    //     }
    // }

    const handleCloseSession = () => {
        setShowUserMenu(false)
        Cookies.remove("Token")
        localStorage.removeItem('loggedUser')
        localStorage.removeItem('userLocalPw')
        setLogged(false)
    }

    // const cleanStorage = () => {
    //     localStorage.removeItem('loggedUser')
    //     localStorage.removeItem('userLocalPw')
    //     sessionStorage.removeItem('loggedUser')
    //     sessionStorage.removeItem('userLocalPw')
    // }


    // Watcher


    const checkTokenValidity = async () => {
        if (!logged) { return };
        let test = await testUserToken();
        if (test?.data?.status) {
            setLogged(true);
        } else {
            setLogged(false);
            Swal.fire({ icon: 'warning', text: 'Esta sesión ha caducado. Ingresa de nuevo para seguir operando.' });
            setLoginForm({
                userName: '',
                password: '',
                ownerName: '',
            })
            Cookies.remove("Token")
            localStorage.removeItem('loggedUser')
            localStorage.removeItem('userLocalPw')

        }
    }

    // useEffect(() => {
    //     checkTokenValidity();
    //     const intervalId = setInterval(() => {
    //         checkTokenValidity();
    //     }, 60000);

    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);


    return (
        <div className="App">

            <header className={`App-header ${!logged ? 'loginFirstStep' : ''}`} >
                {!logged && <div className="position-absolute w-100" style={{ right: '0px', left: '0px', width: '100%' }}>
                    <img className="w-100" src={background}></img>
                </div>}
                {showNotificationsModal &&
                    <>

                        <NotificationsModal
                            notifications={notifications}
                            setShowNotificationModal={setShowNotificationModal}>
                        </NotificationsModal>
                    </>}

                {logged ?
                    <>
                        <div className='navba-rounded ' style={{ minWidth: '525px' }}>
                            <div className='m-auto position-relative py-1'>
                                <div className='d-flex align-items-center justify-content-between'>


                                    <div className='d-flex align-items-center'>
                                        {connectionStatus ?
                                            <span style={{ fontSize: '12px', color: "var(--main-color)" }} className='d-flex justify-content-center align-items-center mx-3 darkBackground px-3 py-1'>
                                                <RiRadioButtonLine size={16} className='mx-1'></RiRadioButtonLine>
                                                Conectado
                                                {ownerInfo?.platform === 'ganamos' &&
                                                    <div className="mx-2" style={{ fontSize: '12px' }}>(Ganamos.net)</div>
                                                }
                                                {ownerInfo?.platform === 'pulpobet' &&
                                                    <div className="mx-2" style={{ fontSize: '12px' }}>(Pulpobet)</div>
                                                }
                                                {ownerInfo?.platform === 'megafaraon' &&
                                                    <div className="mx-2" style={{ fontSize: '12px' }}>(Megafaraon)</div>
                                                }
                                            </span>
                                            :
                                            <span disabled={connecting} onClick={tryConnect} style={{ fontSize: '12px', cursor: connecting ? '' : 'pointer' }} className='text-danger d-flex justify-content-center align-items-center mx-3 darkBackground px-3 py-1 '>
                                                <RiRadioButtonLine size={16} className='mx-1'></RiRadioButtonLine>
                                                {connecting ? 'Conectando..' : 'Conectar'}
                                            </span>
                                            // <div className='d-flex flex-column'>
                                            //     <h6 className='text-danger mx-5'>Desconectado</h6>
                                            //     <button disabled={connecting} onClick={tryConnect} className='btn btn-sm btn-danger py-0 w-50 m-auto'>{connecting ? 'Conectando..' : 'Conectar'}</button>
                                            // </div>
                                        }
                                    </div>

                                    <div className='position-relative d-flex align-items-center'>

                                    </div>

                                    <div className='d-flex align-items-center darkBackground px-1 py-1 '>
                                        <Col className='rounded'>
                                            <div className='position-relative d-flex align-items-center d-flex justify-content-between'>
                                                <div className="position-relative d-flex align-items-center">
                                                    <BiUser color='gray' size={22}></BiUser>
                                                    <small className='mx-2'>{userInfo?.name}</small>
                                                    <div onClick={() => setShowNotificationModal(!showNotificationsModal)} className='position-relative d-flex align-items-center' style={{ cursor: 'pointer' }}>
                                                        <MdNotificationsNone color='gray' size={22}></MdNotificationsNone>
                                                        {notificationsCount > 0 && <div className='position-absolute' style={{ right: '0', top: '0' }}>
                                                            <small className='bg-danger px-1 rounded-circle d-flex items-center' style={{ fontSize: '10px' }}>{notificationsCount}</small>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center btn py-0 greem-hover' style={{ fontSize: '12px' }} onClick={handleCloseSession}>
                                                    Cerrar sesión <MdNavigateNext className='mx-2 text-white blue-hover' size={14}></MdNavigateNext >
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className='secondRow'>
                            <div className="omponent_container mb-2">
                                <NewUser fastMode={fastMode} fetchedData={fetchedData} userLoggedName={userLoggedName} ownerInfo={ownerInfo} checkTokenValidity={checkTokenValidity}></NewUser>
                            </div>

                            <div className="component_container">
                                <ManipulateCoins fastMode={fastMode} fetchedData={fetchedData} userLoggedName={userLoggedName} ownerInfo={ownerInfo} checkTokenValidity={checkTokenValidity}></ManipulateCoins>
                            </div>
                        </div>


                        <div className='firstRow'>
                            <div className="copypastes mx-2">
                                <CopyPastes fetchedData={fetchedData}></CopyPastes>
                                <MoreInfo fetchedData={fetchedData}></MoreInfo>
                            </div>
                        </div>

                        <div className="mt-2">
                            <DataHandler fetchedData={fetchedData} getData={getData}></DataHandler>
                        </div>

                        <div style={{ fontSize: '10px' }} className="mt-3 d-flex align-items-center">
                            <BsInfoCircle className="mx-1" /> Si estás experimentando problemas, prueba
                            <span className="mx-1" onClick={() => window.location.reload()} style={{ cursor: 'pointer', color: 'skyblue', textDecoration: 'underline' }}>
                                {' '}reiniciar
                            </span>
                        </div>
                    </>
                    :

                    <div className=''>

                        {/* <div className='mb-3'>
                            {loginWithUseAndPassword ?
                                <button className='btn btn-dark mt-2 mx-1' onClick={() => setLoginWithUserAndPassword(false)}>Usar patrón</button>
                                :
                                <button className='btn btn-dark mt-2 mx-1' onClick={() => setLoginWithUserAndPassword(true)}>Usar usuario y contraseña</button>
                            }
                        </div> */}

                        {!loginWithUseAndPassword ?

                            <Container style={{ width: '250px', height: '250px' }}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number, index) => {
                                    if (index % 3 === 0) {
                                        return (
                                            <Row key={number}>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9].slice(index, index + 3).map(n => (
                                                    <Col className='m-0 px-2' key={n} style={{ width: '33.3%', height: '33.3%' }}>
                                                        <button disabled={disableButtons} id={n} onClick={handlePassword} className={`btn ${disableButtons ? 'btn-danger' : isPressed[n] ? 'btn-info' : 'btn-secondary'} } my-2`} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {n}
                                                        </button>
                                                    </Col>
                                                ))}
                                            </Row>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                <Container>
                                    <h1>{password?.map((e) => "* ")}</h1>
                                    {password.length === 4 && <button onClick={handlePassword} className='btn btn-dark'>ENTRAR</button>}
                                </Container>



                            </Container>
                            :
                            <div className='mt-5 userLoginBox mx-2 position-relative '>
                                <div className='mb-3' style={{ color: "var(--main-color)" }}>
                                    <BiUserCircle size={50}></BiUserCircle>
                                </div>
                                <div>
                                    <span style={{ fontSize: '13px' }}>Para iniciar sesión, introducir administrador, usuarios y contraseña</span>
                                    <form onSubmit={handleLoginWithPassword}>

                                        <div className="input-group-sm mb-2 mt-3">
                                            <small className="d-flex justify-content-start">Nombre Administrador</small>
                                            <input onChange={handleLoginForm} name='ownerName' value={loginForm.ownerName} type="text" className="customDarkInputLogin loginInput text-black form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder='Escribir administrador' />
                                        </div>

                                        <div className="input-group-sm mb-2">
                                            <small className="d-flex justify-content-start">Usuario</small>
                                            <input onChange={handleLoginForm} name='userName' value={loginForm.userName} type="text" className="customDarkInputLogin loginInput text-black form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder='Escribir usuario' />
                                        </div>

                                        <div className="input-group-sm mb-3">
                                            <small className="d-flex justify-content-start">Contraseña</small>
                                            <input onChange={handleLoginForm} name='password' value={loginForm.password} type="password" className="customDarkInputLogin loginInput text-black form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder='Escribir contraseña' />
                                        </div>

                                        {errorLogin &&
                                            <div>
                                                <small className='text-danger mt-3'><b>Credenciales inválidas. Verifica los datos y vuelve a intentarlo.</b></small>
                                            </div>
                                        }
                                        {/* <div className='w-100 d-flex justify-content-start align-items-center'>
                                        <input onChange={(e) => setKeepSession(e.target.checked)} className='d-flex justify-content-center align-items-center' type='checkbox' /><small className='mx-1'>Mantener iniciada la sesión</small>
                                    </div> */}
                                        <div>
                                            <button type='submit' disabled={!loginForm.ownerName || !loginForm.userName || !loginForm.password} className='btnCustomLogin mt-2 w-100 py-1 border-0' style={{ fontSize: '14px' }}>
                                                Iniciar sesión
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }

                    </div>
                }

            </header >
        </div >
    )
}
