import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import './MoreInfo.css'
import { fetchAdminPublicInfo, fetchButtons } from '../../Copy-pastes/API';
import { getPaymentScaleUsers } from '../../../API/API';

export const MoreInfo = (props) => {

    const [paymentScaleInfo, setPaymentScaleInfo] = useState('')
    const [adminInfo, setAdminInfo] = useState({})

    const getPaymentScaleinfo = async () => {
        let paymentScale = await getPaymentScaleUsers();
        setPaymentScaleInfo(paymentScale ?? '')
    }


    const handleEscalaDePagos = async () => {
        Swal.fire({
            title: 'Explicación escala de pagos',
            html: `<div class="my-custom-class">
            ${paymentScaleInfo.replace(/\n/g, '<br />')}
         </div>`,
            icon: 'info',
            background: '#495057',
            showConfirmButton: false,
            customClass: {
                popup: 'bg-dark',
                title: 'text-light',
                htmlContainer: 'text-light',
                confirmButton: 'bg-primary',
                cancelButton: 'bg-danger'
            }
        });
    }

    const [extraButtons, setExtraButtons] = useState([])

    useEffect(() => {
        fetchButtons().then(res => {
            const extraButtons = [];
            res.forEach(button => { if (button.category === 'extra') { extraButtons.push(button); } });
            setExtraButtons(extraButtons);
        });
        getPaymentScaleinfo();
        getPublicAdminInfo();
    }, []);

    const getPublicAdminInfo = async () => {
        const info = await fetchAdminPublicInfo()
        if (info) {
            setAdminInfo(info)
        }
    }

    const handleCopyMessage = (message) => {
        const updatedMessages = message.map(m => {
            let updatedMessage = m.text
                .replaceAll('__CBU__', (props.fetchedData?.CBU || 'Consultar CBU'))
                .replaceAll('__ALIAS__', props.fetchedData?.alias || 'Consultar alias')
                .replaceAll('__TITULAR__', props.fetchedData?.titular || 'Consultar titular')
                .replaceAll('__HORA__', props.fetchedData?.hour_limit || 'Consultar ')
                .replaceAll('__LINK__', adminInfo?.pageLink || 'Consultar link')
                .replaceAll('__PASSWORD__', adminInfo?.default_users_password ?? 'hola123')
                .replaceAll('__NOMBRE__', props.fetchedData?.nombre)
            return updatedMessage;
        });

        navigator.clipboard.writeText(updatedMessages[Math.floor(Math.random() * updatedMessages.length)]);
    };


    return (<>
        <div className='my-0 py-0'>
            <div>
                {/* <div>
                    <Dropdown className='mx-1'>
                        <Dropdown.Toggle variant='dark' className='p-0 px-4 text-start' id='dropdown-basic' onClick={handleDropdownToggle}>
                            {showButtons ? '- ' : '+ '}INFO
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleDropdownToggle}>
                                {showButtons ? 'Ocultar' : 'Mostrar'} botones
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                {true && extraButtons.length > 0 && (
                    <>
                        <div>
                            <Col className="darkBackground mb-4 py-1" style={{ minWidth: '520px' }}>
                                <div className='my-0 mx-0' style={{ display: 'flex', alignItems: 'center' }}>
                                    <hr className='my-0 py-0' style={{ flex: 1 }} />
                                    <span className='text-secondary' style={{ fontSize: '10px' }}>EXTRA</span>
                                    <hr className='my-0 py-0' style={{ flex: 1 }} />
                                </div>
                                {/* <button title={minima} onClick={handleMinima} className="btn btn-outline-info my-0 py-0 mx-1 mt-1" type="submit">
                                Minimos
                            </button>
                            <button title={premio_tarde} onClick={handlePremioTarde} className="btn btn-outline-info my-0 py-0 mx-1 mt-1" type="submit">
                                Premio tarde
                            </button>
                            <button title={cbu_anterior} onClick={handleCBUanterior} className="btn btn-outline-info my-0 py-0 mx-1 mt-1" type="submit">
                                CBU Anterior
                            </button>
                            <button title={publicidad} onClick={handlePublicidad} className="btn btn-outline-info my-0 py-0 mx-1 mt-1" type="submit">
                                Publicidad
                            </button>
                            <button title={referido()} onClick={handleLinkReferidos} className="btn btn-outline-info my-0 py-0 mx-1 mt-1" type="submit">
                                Link referidos
                            </button> */}
                                {
                                    extraButtons.map((button, index) =>
                                    (<>

                                        <button
                                            key={index}
                                            title={button.Messages[0]?.text}
                                            onClick={() => handleCopyMessage(button.Messages)}
                                            className="btn btn-customdark my-0 py-0 m-1"
                                            type="submit">{button.name}
                                        </button>

                                    </>))}
                            </Col>
                        </div>
                    </>
                )}
                {paymentScaleInfo && <div>
                    <button title={'Escala de pagos'} onClick={handleEscalaDePagos} className="btn btn-outline-secondary my-0 py-0 mx-1" type="submit">
                        Escala de pagos
                    </button>
                </div>
                }
            </div>
        </div>

    </>)
}
