import axios from 'axios'
import { getApiConfig } from '../../../API/getConfig';

export const getSupervisorInfo = async (buttonId) => {
    const config = getApiConfig()
    let result;
    try {
        const result = await axios.get(`/supervisor/me`, config)
        return result
    } catch (error) {
        console.log(error)
    }

    return result
}

export const getSupervisorUsers = async (page) => {
    const config = getApiConfig()
    try {
        const result = await axios.get(`/supervisor/users?page=${page}`, config)
        return result
    } catch (error) {
        console.log(error)
    }
    return null
}

export const getSupervisorUserById = async (id) => {
    const config = getApiConfig()
    try {
        const result = await axios.get(`/supervisor/users/${id}`, config)
        return result
    } catch (error) {
        console.log(error)
    }
    return null
}
